@import "../scss/index.scss";

.background-image .descriptions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.background-image .descriptions h2 .user-account-header {
  cursor: pointer;
}

.ant-btn-primary {
  background-color: $buy-color;
  background: $buy-color  !important;
  border: none;
  opacity: 1;
  font-size: 24px;
  height: 46px;
  &:hover {
      background-color: $buy-color;
      opacity: 0.75;
  }
}
.icon-ref{
  color: #e4c984;
}
.ant-btn-default {

  font-size: 24px;
  height: 46px;
 
}
.ant-card {
    margin: 5px;
}

.user-account-header {
  position: relative;
  z-index: 1;
  /* Otros estilos necesarios */
}

.user-important{
  font-size: 32px;
  font-weight: 700;
}

.user-account-header {
    &:hover {
        cursor: pointer !important;
    }

    margin-left: 5px;

    &__item-contact {
        color: $up-percent-color;

        &:hover {
            cursor: pointer !important;
        }
    }
}

.ant-descriptions-item-label{
    font-size: 21px;
    font-weight: 700;
}

.onoffswitch {
    position: relative;
    width: 75px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .onoffswitch-checkbox {
    display: none;
  }
  
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 33px;
    padding: 0;
    line-height: 33px;
    font-size: 14px;
    color: white;
    box-sizing: border-box;
    font-weight: normal;
  }
  
  .onoffswitch-inner:before {
    content: "YES";
    padding-left: 8px;
    background-color: #ffffff;
    color: #2d3748;
  }
  
  .onoffswitch-inner:after {
    content: "NO";
    padding-right: 8px;
    background-color: #ffffff;
    text-align: right;
    color: #c4c4c4;
  }
  
  .onoffswitch-switch {
    display: block;
    width: 25px;
    margin: 4.5px;
    background-color: #c4c4c4;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 37px;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: all 0.3s ease-in 0s;
  }
  
  .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  
  .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0px;
    background-color: #2d3748;
  }

  .rd3t-g-7fc87481-a120-4ece-a700-e32ef70aeadd {
transform: translate(500,0) scale(1)!important;
  }
  
  .rd3t-node {
    cursor: pointer;
    fill: #777;
    stroke: #ffb531!important;
    stroke-width: 5px!important;
}
.rd3t-leaf-node{
  cursor: pointer;
  fill: #777;
  stroke: #ffb531!important;
  stroke-width: 5px!important;
}
.node__root > circle {
    fill: #c99e41!important;
    padding: 50px;
  }
  
  .node__branch > circle {
    fill: #c99e41!important;
  }
  
  .node__leaf > circle {
    fill: #c99e41!important;
    /* Let's also make the radius of leaf nodes larger */
  }

  .labels {
    fill: #FFF;
  }
  .rd3t-label__title{
    fill: #fafafa!important;
  }
 
  .rd3t-link{
  stroke: #fafafa!important;
  }

  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ant-spin {

    color: #ffb531!important;
  }

  .ant-badge-status-success {
    width: 16px; 
    height: 16px
  }

  .ant-badge-status-error {
    width: 16px; 
    height: 16px
  }
  .sponsor{
    color: #ff0000!important;
  }
  
