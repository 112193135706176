@import "./scss/index.scss";
main {
    padding: 0px 25px 25px 25px;
    background-color: $background-color;
    position: relative;
}

header {
    text-align: end;
    position: relative !important;
}

.ant-card {
    background: $header-color;
    border-color: $header-color;
    border-radius: 7px;
}

.ant-descriptions-title {
    
    font-size: 23px;
}

.ant-descriptions-item-content {

font-size: 18px;
}

.footer {
    padding: 9px 13px;
}


.audio-player-container {
    display: flex; /* Establece la visualización en línea de los elementos internos */
    align-items: center; /* Alinea verticalmente los elementos en el centro */
    position: relative;
  }
  
  .audio-player-container button,
  .audio-player-container div.volume-control,
  .audio-player-container input[type="range"] {
    margin-right: 10px; /* Agrega un margen a la derecha de los elementos para espaciarlos */
  }
  @media (max-width: 768px) {
    .audio-player-container {
      flex-direction: column; /* Cambia la dirección de flexión a columna */
      align-items: center; /* Alinea los elementos al centro verticalmente */
    }
  
    .audio-player-container button,
    .audio-player-container div.volume-control,
    .audio-player-container input[type="range"] {
      margin-bottom: 10px; /* Agrega un margen inferior para separar los elementos */
    }
  }




