@import "../scss/index.scss";

.ant-result-subtitle {
    color: white;
}

.ant-result-title {
    color: white;
}

.image-container{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
