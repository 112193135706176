@import "../scss/index.scss";

/*** MAIN MENU ***/
.main-menu-divider-middle {
    background-color: $m-divider-background-color;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
}

.main-menu-divider-all {
    margin-bottom: 30px !important;
    background-color: $m-divider-background-color;
}

.ant-layout-sider {
    padding: 0 !important;
}

.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #f3f3f3!important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>a, .ant-menu-dark .ant-menu-item>span>a {
    color: hsl(0deg 0% 0%)!important;
    font-size: large!important;
    font-family: ui-monospace!important;
}


.ant-menu.ant-menu-inline-collapsed>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title{

    padding: 0 calc(32% - 8px)!important;
}

.ant-menu-inline>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-item, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 45px!important;
}

.max {
    padding: 15px;
    width: 170px;
}
.min {
    padding: 8px;
}

.logo img {
    width: 169px;
}

.buy {
    font-size: 28px;
}   

.ant-modal-title {
    font-size: 25px;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #4a4a4a !important;
}

.img-panel{
    width: 45px;
}