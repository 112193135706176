@import "../scss/index.scss";




.ant-btn-primary {
  background-color: $buy-color;
  background: $buy-color !important;
  border: none;
  opacity: 1;
  font-size: 24px;
  height: 46px;

  &:hover {
    background-color: $buy-color;
    opacity: 0.75;
  }
}

.ant-btn-default {

  font-size: 24px;
  height: 46px;

}

.ant-card {
  margin: 5px;
}

.user-account-header {
  &:hover {
    cursor: pointer !important;
  }

  margin-left: 5px;

  &__item-contact {
    color: $up-percent-color;

    &:hover {
      cursor: pointer !important;
    }
  }
}

.ant-descriptions-item-label {
  font-size: 21px;
  font-weight: 700;
}

.onoffswitch {
  position: relative;
  width: 75px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 33px;
  padding: 0;
  line-height: 33px;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
  font-weight: normal;
}

.onoffswitch-inner:before {
  content: "YES";
  padding-left: 8px;
  background-color: #ffffff;
  color: #2d3748;
}

.onoffswitch-inner:after {
  content: "NO";
  padding-right: 8px;
  background-color: #ffffff;
  text-align: right;
  color: #c4c4c4;
}

.onoffswitch-switch {
  display: block;
  width: 25px;
  margin: 4.5px;
  background-color: #c4c4c4;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 37px;
  border: 2px solid #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
  background-color: #2d3748;
}

.rd3t-g-7fc87481-a120-4ece-a700-e32ef70aeadd {
  transform: translate(500, 0) scale(1) !important;
}

.node__root>circle {
  fill: #ffb531;
  padding: 50px;
}

.node__branch>circle {
  fill: #ffb531;
}

.node__leaf>circle {
  fill: #ffb531;
  /* Let's also make the radius of leaf nodes larger */
}

.labels {
  fill: #FFF;
}

.rd3t-label__title {
  fill: #fafafa !important;
}

.rd3t-link {
  stroke: #fafafa !important;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin {

  color: #ffb531 !important;
}

.ant-badge-status-success {
  width: 16px;
  height: 16px
}

.ant-badge-status-error {
  width: 16px;
  height: 16px
}

.disable-selection {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.course {
  z-index: 2;
}

.background-image::before {
  content: "";
  background-image: url("../assets/fondo-set-app.webp");
  opacity: 0.3;
  /* Ajusta el valor de opacidad según tus necesidades */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.background-image2::before {
  content: "";
  background-image: url("../assets/fondo-set-app.webp");
  opacity: 0.3;
  /* Ajusta el valor de opacidad según tus necesidades */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.preview-image-container {
  max-width: 100%;
  height: auto;
}

.justify-text {
  text-align: justify;
  font-size: 20px;
}

.video-container {
  /* position: relative; */
  width: 100%;
  /* padding-bottom: 56.25%; */
  overflow: hidden;
  height: 500px !important;


  iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px !important;
  }
}

.preview-image-container img {
  width: 100%;
  height: auto;
}

.close-button {
  background-color: #ffb531;
  color: #000;
  border: none;
  padding: 7px 18px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 21px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.close-button:hover {
  background-color: #d3b77a;
  /* Estilos para el efecto hover */
}

.rpv-toolbar__right {
  display: none;
}

.pdf-container {
  max-height: 80vh;
  /* Establece la altura máxima del contenedor */
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical cuando el contenido excede la altura del contenedor */
}

@media (max-width: 768px) {


  .video-container {
    /* position: relative; */
    width: 100%;
    /* padding-bottom: 56.25%; */
    overflow: hidden;
    height: 200px !important;

    iframe {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px !important;
    }
  }
}